





















/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue, Prop } from "vue-property-decorator";

type Headers = {
  text: string;
  align?: string;
  value: string;
  sortable?: boolean;
};

type Desserts = {
  fecha: string;
  notaDescripcion: string;
};

@Component
export default class EquiposHistorial extends Vue {
  @Prop() readonly historial: any;
  // @State(({ appState }) => appState.historial) historial!: Array<any>;

  public headers: Array<Headers> = [
    {
      text: "HISTORIAL DE REPARACIONES / MANTENIMIENTO",
      align: "start",
      sortable: true,
      value: "notaDescripcion"
    },
    {
      text: "FECHA",
      align: "start",
      sortable: true,
      value: "fecha"
    }
  ];

  public formatearFecha(fecha: string): string {
    const fechaAString = `${fecha.replaceAll("/", "-")}T00:00:00`;
    return `${new Date(fechaAString).toLocaleDateString()}`;
  }
}

































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from "vue-property-decorator";
import { Validate } from "vuelidate-property-decorators";
import { required, minLength } from "vuelidate/lib/validators";
import { State, Action } from "vuex-class";

@Component
export default class EquiposForm extends Vue {
  @State(({ appState }) => appState.condominio) condominio!: any;
  @State(({ appState }) => appState.equipos) equipos!: Array<any>;
  @Action registrarEquipo!: Function;
  @Validate({ required, minLength: minLength(8) }) equipoZona = null;

  //REGISTRAR NUEVO MANTENEDOR
  private register(): void {
    const nuevoEquipo = {
      nombre: this.equipoZona
    };
    this.registrarEquipo({
      condoId: this.condominio.id,
      data: nuevoEquipo
    });
    this.clearForm();
  }

  //LIMPIAR FORM
  private clearForm(): void {
    this.$v.$reset();
    this.equipoZona = null;
  }

  //HABILITAR SUBMIT
  get isDisabled(): boolean {
    return !this.equipoZona ? true : false;
  }

  //VALIDACIONES
  get equipoZonaErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.equipoZona.$dirty) return errors;
    !this.$v.equipoZona.required && errors.push("El campo es requerido");
    !this.$v.equipoZona.minLength &&
      errors.push("Debe contener minimo 5 digitos");
    return errors;
  }
}




































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from "vue-property-decorator";

import AreasTabla from "@/components/Areas/AreasTabla.vue";
import AreasForm from "@/components/Areas/AreasForm.vue";

import EquiposTabla from "@/components/Equipos/EquiposTabla.vue";
import EquiposForm from "@/components/Equipos/EquiposForm.vue";
import EquiposHistorial from "@/components/Equipos/EquiposHistorial.vue";
import { State, Action } from "vuex-class";

@Component({
  components: {
    AreasTabla,
    AreasForm,
    EquiposTabla,
    EquiposForm,
    EquiposHistorial
  }
})
export default class Equipos extends Vue {
  @State(({ appState }) => appState.condominio) condominio!: any;
  @State(({ appState }) => appState.historial) historial!: Array<any>;
  @Action obtenerHistorialDeEquipo!: Function;
  public tieneHistorial = false;

  private mostrarHistorial(idEquipo: number) {
    this.obtenerHistorialDeEquipo({
      idCondo: this.condominio.id,
      idEquipo: idEquipo
    });
  }
}

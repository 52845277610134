































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from "vue-property-decorator";
import { Validate } from "vuelidate-property-decorators";
import { required, minLength } from "vuelidate/lib/validators";
import { State, Action } from "vuex-class";

@Component
export default class EquiposForm extends Vue {
  @State(({ appState }) => appState.condominio) condominio!: any;
  @State(({ appState }) => appState.areas) areas!: Array<any>;
  @Action registrarArea!: Function;
  @Validate({ required, minLength: minLength(8) }) area = null;

  //REGISTRAR NUEVO MANTENEDOR
  private register(): void {
    const nuevoEquipo = {
      nombre: this.area
    };
    this.registrarArea({
      condoId: this.condominio.id,
      data: nuevoEquipo
    });
    this.clearForm();
  }

  //LIMPIAR FORM
  private clearForm(): void {
    this.$v.$reset();
    this.area = null;
  }

  //HABILITAR SUBMIT
  get isDisabled(): boolean {
    return !this.area ? true : false;
  }

  //VALIDACIONES
  get areaErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.area.$dirty) return errors;
    !this.$v.area.required && errors.push("El campo es requerido");
    !this.$v.area.minLength && errors.push("Debe contener minimo 5 digitos");
    return errors;
  }
}









































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue, Watch } from "vue-property-decorator";
import { State, Action } from "vuex-class";

type Equipo = {
  nombre: string;
  id?: number;
  historial?: Array<any>;
};
type Headers = {
  text: string;
  align?: string;
  value: string;
  sortable?: boolean;
};

@Component
export default class EquiposList extends Vue {
  @State(({ appState }) => appState.condominio) condominio!: any;
  @State(({ appState }) => appState.equipos) equipos!: Array<Equipo>;
  @Action actualizarEquipo!: Function;
  @Action eliminarEquipo!: Function;
  public headers: Array<Headers> = [
    {
      text: "INSTALACIONES",
      align: "start",
      sortable: true,
      value: "nombre"
    },
    { text: "ACCIONES", value: "actions", sortable: false }
  ];

  // @Action obtenerEquipos!: Function;
  public dialog = false;
  public dialogDelete = false;
  public desserts: Array<any> = [];
  public editedIndex = -1;
  public editedItem: Equipo = {
    nombre: ""
  };
  public defaultItem: Equipo = {
    nombre: ""
  };

  private mostrarHistorial(equipo: Equipo): void {
    this.dialog === true || this.dialogDelete === true
      ? null
      : this.$emit("mostrarHistorial", equipo.id);
  }

  get formTitle(): string {
    return this.editedIndex === -1 ? "Agregar" : "Editar equipo";
  }
  @Watch("dialog")
  dialogName(val: any) {
    val || this.close();
  }
  @Watch("dialogDelete")
  dialogDeleteName(val: any) {
    val || this.closeDelete();
  }

  private editItem(item: any) {
    const copyEquipos = this.equipos;
    this.editedIndex = copyEquipos.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialog = true;
  }
  private deleteItem(item: any) {
    const copyEquipos = this.equipos;
    this.editedIndex = copyEquipos.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialogDelete = true;
  }
  private deleteItemConfirm() {
    const copyEquipos = this.equipos;
    copyEquipos.splice(this.editedIndex, 1);
    this.eliminarEquipo({
      idCondo: this.condominio.id,
      idEquipo: this.editedItem.id
    });
    this.closeDelete();
  }
  private close() {
    this.dialog = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }
  private closeDelete() {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }
  public save() {
    const copyEquipos = this.equipos;
    if (this.editedIndex > -1) {
      Object.assign(copyEquipos[this.editedIndex], this.editedItem);
      console.info(this.editedItem);
      this.actualizarEquipo({
        idCondo: this.condominio.id,
        idEquipo: this.editedItem.id,
        data: this.editedItem
      });
    } else {
      // copyEquipos.push(this.editedItem);
      // console.info(this.editedItem);
      // this.registrarGasto({ idEvento: this.id, data: this.editedItem });
    }
    this.close();
  }
}
